<template>
  <modal :modal.sync="modal" @changeModal="e => $emit('change-modal', e)">
    <div class="modal__content">
      <div class="modal__header">
        <h2 class="modal__title">
          Comentários
        </h2>
      </div>
      <div>
        <div>
          <textarea v-model="comment" style="padding: 10px; width: 100%; height: 80px; border: 1px solid gray; border-radius: 10px;"></textarea>
        </div>
        <div style="text-align: center;">
          <button
            style="width: 150px;"
            :disabled="loading"
            type="button"
            @click="saveComment"
            class="button_outline"
          >
            {{loading ? 'enviando...' : 'salvar comentário' }}
          </button>
        </div>
        <div style="margin-top: 30px; overflow: auto;">
          <div v-for="comment in comments" :key="comment.id" style="margin-top: 30px;">
            <p style="font-size: 15px;"><b>{{comment.user.name}}</b> {{comment.formated_date}}</p>
            <p class="comment-box">{{comment.comment}}</p>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from 'vue'
import swal from 'sweetalert'
import axios from 'axios'

export default Vue.extend({
  data: () => ({
    loading: false,
    comments: [],
    comment: null
  }),
  computed: {
  },
  methods: {
    async saveComment () {
      const url = 'comments'
      this.loading = true
      try {
        const body = {
          comment: this.comment,
          order_id: this.orderId
        }

        const { data, status } = await axios.post(url, body)
        console.log('response', data)
        this.alert(status === 200 ? 'success' : 'error', data.message)
        if (status === 200) {
          this.comment = null
          this.comments = data.data
        }
        this.loading = false
      } catch (error) {
        const { status, data } = error.response
        this.alert(status === 200 ? 'success' : 'error', data.message)
      } finally {
        this.loadingCollection = false
        this.loading = false
      }
    },
    async getComments (orderId) {
      const url = 'comments/' + orderId

      const { data, status } = await axios.get(url)
      console.log('data', data)
      if (status === 200) {
        this.comments = data.data
      }
    },
    alert (icon, message) {
      swal({
        title: 'Atenção!',
        text: message,
        icon: icon,
        button: 'Ok'
      })
    }
  },
  props: {
    modal: Boolean,
    selectedMaterial: Object,
    orderId: Number
  },
  components: {
    modal: () => import('@/components/modal')
  },
  created () {
  },
  mounted () {
  },
  watch: {
    modal () {
    },
    selectedMaterial: {
      deep: true,
      handler (val) {
        if (!val) return null
        // this.formData = val
      }
    },
    orderId: {
      deep: true,
      handler (val) {
        this.getComments(val)
      }
    }
  }
})
</script>

<style scoped>

.comment-box {
  margin-top: 5px;
  background-color: #f7f7f7;
  padding: 10px;
  text-align: justify;
  border-radius: 10px;
}
.have-destination {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
.red {
  color: #ce2c2c;
}
button {
  margin: auto;
  margin-top: 1rem;
}
.banks__item--content {
  margin-left: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banks__item--content div:last-child {
  padding-left: 16px;
}

.banks__item input:checked + label::after {
  left: 10px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.recyclable {
  margin: 1.5rem 0;
  position: relative;
}

.recyclable-section p {
  position: relative;
  cursor: pointer;
}

.modal-chevron {
  color: #05b446;
  position: absolute;
  right: 10px;
  font-size: 22px;
  top: 50%;
  transition: 0.2s;
  transform: translateY(-50%) rotate(360deg);
}
.modal-chevron.open {
  transform: translateY(-50%) rotate(180deg);
}

.recyclable-content {
  margin: 1rem auto 2rem auto;
  width: calc(100% - 1rem);
}

.recyclable-content li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.recyclable-content li i {
  margin-right: 1rem;
  font-size: 14px;
  display: block;
}

.recyclable-content li i.fa-check {
  color: #47be71;
}
</style>
